<template>
  <div v-if="isLoaded">
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!company.has_paid_subscription"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Sie müssen ein kostenpflichtes Abonement abschliessen um ihre
        Niederlassugen zu erfassen. Das Erfassen von Niederlassugnen ist nicht
        im Grundpreis des Jahresabonement enthalten, kann jedoch als Option
        hinzufügen.
      </template>
      <router-link
        to="/adjust-subscription"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Abonement abschliessen
        </b-button>
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->
    <!--begin::Notice-->
    <b-jumbotron
      v-else-if="branchOffices.length == 0"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Die Option um Ihre Niederlassungen zu erfassen ist in Ihrem Abonment
        nicht enthalten.
      </template>
      <hr class="my-4" />
      <p>
        Sie können jedoch weitere Optionen jederzeit zu Ihrem Abonoment
        hinzufügen.
      </p>
      <router-link
        to="/add-subscription-option"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Optionen hinzufügen
        </b-button>
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->
    <!--begin::Card-->
    <div class="card card-custom" v-else>
      <!-- begin::Form-->
      <b-form class="form" v-on:submit.prevent="save">
        <!--begin::Body-->
        <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <div
                class="border-bottom mb-10"
                v-for="(v, index) in $v.branchOffices.$each.$iter"
                :key="index"
              >
                <h4 class="mb-10 font-weight-bold text-dark">
                  Niederlassung {{ parseInt(index) + 1 }}
                </h4>
                <div class="row">
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Strasse"
                      v-model="branchOffices[index].street"
                      :v="v.street"
                      errorMessage="Das ist ein Pflichtfeld."
                    />
                  </div>
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Nummer"
                      v-model="branchOffices[index].street_nr"
                      :v="v.street_nr"
                      errorMessage="Das ist ein Pflichtfeld."
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Ortschaft</label>
                  <b-form-input
                    class="form-control form-control-lg form-control-solid"
                    list="localities-list-filter-id"
                    placeholder="Ortschaft"
                    v-model="v.locality.$model"
                    :state="validateState('locality', v)"
                    aria-describedby="input-locality-feedback"
                  ></b-form-input>
                  <datalist id="localities-list-filter-id">
                    <option
                      v-for="locality in getLocalities"
                      v-bind:key="locality.id"
                      >{{ formatLocality(locality) }}
                    </option>
                  </datalist>
                  <b-form-invalid-feedback id="input-locality-feedback">
                    Das ist keine gültige Ortschaft.
                  </b-form-invalid-feedback>
                </div>

                <h4 class="mb-10 mt-10 font-weight-bold text-dark">
                  Kontakinformationen
                </h4>
                <div class="row">
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Mail"
                      v-model="branchOffices[index].mail"
                      :v="v.mail"
                      errorMessage="Das ist ein Pflichtfeld und muss eine gültige Email Adresse sein."
                    />
                  </div>
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Telefon"
                      v-model="branchOffices[index].phone"
                      :v="v.phone"
                      errorMessage="Das ist ein Pflichtfeld."
                      :helpText="'Bsp: ' + phone_example"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Mobile"
                      v-model="branchOffices[index].mobile"
                      :v="v.mobile"
                      errorMessage="Das ist ein Pflichtfeld."
                      :helpText="'Bsp: ' + phone_example"
                    />
                  </div>
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Fax"
                      v-model="branchOffices[index].fax"
                      :v="v.fax"
                      errorMessage="Das ist ein Pflichtfeld."
                      :helpText="'Bsp: ' + phone_example"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Website"
                      v-model="branchOffices[index].website"
                      :v="v.website"
                      errorMessage="Das ist ein Pflichtfeld."
                    />
                  </div>
                  <div class="col-xl-6">
                    <ValidatedInput
                      name="Standort Umkreis"
                      v-model="branchOffices[index].location_scope"
                      :v="v.location_scope"
                      errorMessage="Das ist ein Pflichtfeld."
                      helpText="Maximale Distanz in km von dieser Niederlassung aus."
                    />
                  </div>
                </div>
                <div class="form-group">
                  <label>Korrespondenzsprache</label>
                  <b-form-checkbox-group
                    v-model="branchOffices[index].languages_of_correspondence"
                    :state="validateState('languages_of_correspondence', v)"
                  >
                    <b-form-checkbox
                      v-for="language in getLanguages"
                      :key="language.shortcut"
                      :value="language.id"
                      name="language-selection"
                      inline
                    >
                      {{ language.language }}
                    </b-form-checkbox>
                    <b-form-invalid-feedback
                      :state="validateState('languages_of_correspondence', v)"
                    >
                      Wählen Sie mindestens eine Sprache aus.
                    </b-form-invalid-feedback>
                  </b-form-checkbox-group>
                </div>
              </div>
              <!--begin: Actions -->
              <div
                class="d-flex justify-content-between"
                v-if="branchOffices.length > 0"
              >
                <div class="mr-2"></div>
                <div>
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Speichern
                  </button>
                </div>
              </div>
              <!--end: Actions -->
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required, numeric, email } from "vuelidate/lib/validators";
import ValidatedInput from "@/components/ValidatedInput";
import { phoneNumberValidationMixin } from "@/mixins/phoneNumberValidationMixin";
import { alertMixin } from "@/mixins/alertMixin";
import { utilMixin } from "@/mixins/utilMixin";
import { localityValidationMixin } from "@/mixins/localityValidationMixin";
import { LOAD_LANGUAGES } from "@/store/languages.module";
import {
  LOAD_USER_BRANCH_OFFICES,
  UPDATE_USER_BRANCH_OFFICES
} from "@/store/company.module";
import { LOAD_LOCALITIES } from "@/store/localities.module";
import { LOAD_USER_COMPANY } from "@/store/company.module";

function phoneNumberValidation(value, vm) {
  return this.validatePhoneNumber(value, vm, "phone", true);
}

function mobileNumberValidation(value, vm) {
  return this.validatePhoneNumber(value, vm, "mobile", true);
}

function faxNumberValidation(value, vm) {
  return this.validatePhoneNumber(value, vm, "fax", true);
}

function localityValidation(value) {
  return this.checkLocality(value);
}

export default {
  components: { ValidatedInput },
  mixins: [
    validationMixin,
    phoneNumberValidationMixin,
    alertMixin,
    utilMixin,
    localityValidationMixin
  ],
  name: "BranchOffices",
  data() {
    return {
      branchOffices: [],
      company: {},
      isLoaded: false
    };
  },
  validations: {
    branchOffices: {
      $each: {
        street: {
          required
        },
        street_nr: {
          required
        },
        locality: {
          required,
          localityValidation
        },
        mail: {
          email
        },
        mobile: {
          mobileNumberValidation
        },
        phone: {
          phoneNumberValidation
        },
        fax: {
          faxNumberValidation
        },
        website: {},
        location_scope: {
          required,
          numeric
        },
        languages_of_correspondence: {
          required
        }
      }
    }
  },
  mounted() {
    this.loadCompany();
  },
  methods: {
    validateState(name, v) {
      const { $dirty, $error } = v[name];
      return $dirty ? !$error : null;
    },
    async loadCompany() {
      // Load first the languages before the company is loaded
      await Promise.all([
        this.$store.dispatch(LOAD_LANGUAGES),
        this.$store.dispatch(LOAD_LOCALITIES),
        this.$store.dispatch(LOAD_USER_BRANCH_OFFICES),
        this.$store.dispatch(LOAD_USER_COMPANY)
      ]).then(() => {
        // Promise finished -> load user company
        this.branchOffices = this.getUserBranchOffices;
        this.setLocality();
        this.company = this.getUserCompany;
      });
      this.isLoaded = true;
    },
    save() {
      this.$v.branchOffices.$touch();
      if (this.$v.branchOffices.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      // Replace the locality name with the corresponding id
      const payload = JSON.parse(JSON.stringify(this.branchOffices));
      payload.forEach(branchOffice => {
        branchOffice.locality = this.getLocalityIdForName(
          branchOffice.locality
        );
      });

      this.$store
        .dispatch(UPDATE_USER_BRANCH_OFFICES, payload)
        .then(response => {
          this.branchOffices = response.data;
          this.setLocality();
          this.showChangesSavedAlert();
          // Clear flag after upload
          this.new_image = false;
        });
    },
    setLocality() {
      this.branchOffices.forEach(branchOffice => {
        let locality = this.getLocalityNameForId(branchOffice.locality);
        if (locality) {
          branchOffice.locality = locality;
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      "getUserBranchOffices",
      "getLanguages",
      "getLocalities",
      "getUserCompany"
    ])
  }
};
</script>
